import { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Text } from 'ui'
import { useGetOnboardingDetails } from '~/api/onboarding/hooks/use-get-onboarding-details'
import FullPageLoader from '~/components/full-page-loader'
import { useOrganizationSearchParams } from '~/hooks/use-organization-search-params'
import nextI18NextConfig from '../../next-i18next.config'

const OrganizationIdPage = () => {
  const { t } = useTranslation('common')
  const { organizationId } = useOrganizationSearchParams()
  const { data } = useGetOnboardingDetails(organizationId, {
    pollingInterval: 2000,
  })
  const router = useRouter()

  useEffect(() => {
    if (data?.organization?.onboardingCompleted) {
      router.replace({
        pathname: '/[organizationId]/billing',
        query: { organizationId },
      })
    }
  }, [data])

  return (
    <>
      <Head>
        <title>Native Casa | {t('pageTitle.prepare')}</title>
      </Head>
      <Container flex={1} display="flex" justifyContent="center" alignItems="center">
        <Container flexGap="2rem">
          <FullPageLoader />
          <Container>
            <Text as="p" textAlign="center">
              {t('gettingYourCasa')}
            </Text>
            <Text as="p" textAlign="center">
              {t('adminSpace')}
            </Text>
          </Container>
        </Container>
      </Container>
    </>
  )
}
export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const translations = await serverSideTranslations(
    ctx.locale ?? 'en',
    ['onboardingUser', 'common'],
    nextI18NextConfig
  )

  return { props: { ...translations } }
}

export default OrganizationIdPage
