import { useQuery } from '@tanstack/react-query'
import { OnboardingService } from '~/api/generated'

export const ONBOARDING_DETAILS_QUERY_KEY = 'onboarding-details'

interface UseGetOnboardingDetails {
  pollingInterval?: number
}

export const useGetOnboardingDetails = (
  organizationId?: string,
  hookParams?: UseGetOnboardingDetails
) => {
  return useQuery(
    [ONBOARDING_DETAILS_QUERY_KEY, organizationId],
    async () => OnboardingService.organizationOnboarding(organizationId!),
    {
      enabled: !!organizationId,
      refetchInterval: hookParams?.pollingInterval,
    }
  )
}
